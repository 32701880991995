<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-form @submit="onSubmit" @reset="onReset">
      <b-card>
        <!-- 角色名称 -->
        <b-row>
          <b-col lg="10">
            <form-row>
              <b-form-group
                label="Role:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="role"
                label-class="requiredRow"
              >
                <b-form-input
                  id="role"
                  v-model="form.roleName"
                  placeholder="Please enter"
                  maxlength="20"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <div style="text-align: center; margin-bottom: 1em">
                  {{ form.roleName.length }}/20
                </div>
              </template>
            </form-row>

            <form-row>
              <b-form-group
                label="Role Status:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="deviceType"
                label-class="requiredRow"
              >
                <b-form-radio
                  v-for="(item, key) in types.status"
                  :key="key"
                  class="custom-control-inline pt-2"
                  v-model="form.isDelete"
                  :name="'deviceType-options' + key"
                  :value="item.value"
                  >{{ item.text }}
                </b-form-radio>
              </b-form-group>
            </form-row>
          </b-col>
        </b-row>

        <!-- 权限设置 -->
        <b-tabs pills>
          <b-tab title="Function Permission Setting" active>
            <b-card-text>
              <b-table :items="form.navMenuList" :fields="fields" caption-top>
                <template v-slot:cell(module)="data">
                  <b-form-checkbox
                    v-model="data.item.allSelected"
                    :indeterminate="data.item.indeterminate"
                    aria-describedby="permissions"
                    aria-controls="permissions"
                    :disabled="
                      (data.item.title === 'Home' && isUnicoreus) ||
                      (data.item.title === 'Dashboard' && isIkrusher)
                        ? true
                        : false
                    "
                    @change="chooseAll(!data.item.allSelected, data.item)"
                  >
                    <div>{{ data.item.title }}</div>
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(page)="data">
                  <div v-for="i in data.item.children" :key="i.id">
                    <!-- v-if="i.children" -->
                    <b-form-checkbox
                      v-model="i.allSelected"
                      :indeterminate="i.indeterminate"
                      aria-describedby="permissions"
                      aria-controls="permissions"
                      @change="toggleAll(!i.allSelected, i, data.item)"
                      @change.native="
                        disabledButton(i.selected, i.children, i, data.item)
                      "
                    >
                      <!-- {{ i.allSelected ? "Un-select All" : "Select All" }} -->
                      {{ i.title }}
                    </b-form-checkbox>
                  </div>
                </template>
                <template v-slot:cell(permissions)="data">
                  <div v-for="i in data.item.children" :key="i.id">
                    <div v-if="!i.children"><br /></div>
                    <b-form-checkbox-group
                      v-model="i.selected"
                      :options="i.children"
                      class="ml-4"
                      value-field="id"
                      text-field="title"
                      :disabled="i.disabled"
                      @change.native="
                        disabledButton(i.selected, i.children, i, data.item)
                      "
                    ></b-form-checkbox-group>
                  </div>
                </template>
              </b-table>
            </b-card-text>
          </b-tab>
          <b-tab title="App Function Permission Setting">
            <b-card-text>
              <b-table
                :items="form.appNavMenuList"
                :fields="fields"
                caption-top
              >
                <template v-slot:cell(module)="data">
                  <b-form-checkbox
                    v-model="data.item.allSelected"
                    :indeterminate="data.item.indeterminate"
                    aria-describedby="permissions"
                    aria-controls="permissions"
                    @change="chooseAll(!data.item.allSelected, data.item)"
                  >
                    <div>{{ data.item.title }}</div>
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(page)="data">
                  <div v-for="i in data.item.children" :key="i.id">
                    <!-- v-if="i.children" -->
                    <b-form-checkbox
                      v-model="i.allSelected"
                      :indeterminate="i.indeterminate"
                      aria-describedby="permissions"
                      aria-controls="permissions"
                      @change="toggleAll(!i.allSelected, i, data.item)"
                      @change.native="
                        disabledButton(i.selected, i.children, i, data.item)
                      "
                    >
                      <!-- {{ i.allSelected ? "Un-select All" : "Select All" }} -->
                      {{ i.title }}
                    </b-form-checkbox>
                  </div>
                </template>
                <template v-slot:cell(permissions)="data">
                  <div v-for="i in data.item.children" :key="i.id">
                    <div v-if="!i.children"><br /></div>
                    <b-form-checkbox-group
                      v-model="i.selected"
                      :options="i.children"
                      class="ml-4"
                      value-field="id"
                      text-field="title"
                      :disabled="i.disabled"
                      @change.native="
                        disabledButton(i.selected, i.children, i, data.item)
                      "
                    >
                    </b-form-checkbox-group>
                  </div>
                </template>
              </b-table>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      <b-form-group label-cols-lg="3">
        <b-button type="submit" variant="primary" class="custom-width"
          >Save</b-button
        >
        <b-button type="reset" class="ml-4 custom-width">Cancel</b-button>
      </b-form-group>
    </b-form>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
import { addDays } from "@fullcalendar/core";
import { mapGetters } from "vuex";
import FormRow from "@/components/FormRow.vue";
/**
 * FAQ Add component
 */
export default {
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
  },
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    FormRow,
  },
  data() {
    return {
      page: {
        title: this.editId ? "Modify Role" : "Create Role",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "System Settings",
            // href: "/"
          },
          {
            text: "Role Mgmt",
            active: true,
          },
          {
            text: this.editId ? "Modify Role" : "Create Role",
            active: true,
          },
        ],
      },
      fields: [
        { key: "module", label: "Module" },
        { key: "page", label: "Page" },
        {
          key: "permissions",
          label: "Permissions",
        },
      ],
      form: {
        roleName: "",
        isDelete:1,
        navMenuList: [],
        menuId: [],
        appNavMenuList: [], //APP功能权限列表
        appMenuId: [],
      },
      types: {
        status: [
          { text: "Enable", value: 1 },
          { text: "Disable", value: 0 },
        ],
      },
      editor: ClassicEditor,
      editId: window.location.search,
      photoName: "",
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    if (this.editId) {
      let id = this.editId.split("=")[1];
      this.formData(id);
    } else {
      this.$api.systemManage.findAllMenu().then((res) => {
        if (res.success) {
          res.data.forEach((element) => {
            if (
              (element.title === "Home" && this.$store.getters.isUnicoreus) ||
              (element.title === "Dashboard" && this.$store.getters.isIkrusher)
            ) {
              element.allSelected = true;
            }
          });
          this.form.navMenuList = res.data;
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
      this.$api.systemManage
        .findDeveloperAllMenu()
        .then((res) => {
          if (res.success) {
            this.form.appNavMenuList = res.data;
          } else {
            this.$toast(ers);
          }
        })
        .catch((error) => {
          this.$toast(error);
        });
    }
  },
  mounted() {},
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$set(this.form, "menuId", []);

      this.form.navMenuList.forEach((item) => {
        if (item.allSelected || item.indeterminate) {
          this.form.menuId.push(item.id);
        }
        if (item.children) {
          item.children.forEach((ele) => {
            if (ele.allSelected || ele.indeterminate) {
              this.form.menuId.push(ele.id);
            }
            if (ele.children && ele.selected && ele.selected.length > 0) {
              this.form.menuId = this.form.menuId.concat(ele.selected);
            }
          });
        }
      });
      this.$set(this.form, "appMenuId", []);
      this.form.appNavMenuList.forEach((item) => {
        if (item.allSelected || item.indeterminate) {
          this.form.appMenuId.push(item.id);
        }
        if (item.children) {
          item.children.forEach((ele) => {
            if (ele.allSelected || ele.indeterminate) {
              this.form.appMenuId.push(ele.id);
            }
            if (ele.children && ele.selected && ele.selected.length > 0) {
              this.form.appMenuId = this.form.appMenuId.concat(ele.selected);
            }
          });
        }
      });
      if (this.form.id) {
        let updateSupplierRoleVo = this.form;
        delete updateSupplierRoleVo.navMenuList;
        delete updateSupplierRoleVo.appNavMenuList;
        this.$api.systemManage
          .roleUpdateById(updateSupplierRoleVo)
          .then((res) => {
            if (res.success) {
              this.$toastBack(res);
            } else {
              if (res.message) {
                this.$toast(res);
              }
            }
          });
      } else {
        let addSupplierRoleVo = this.form;
        delete addSupplierRoleVo.navMenuList;
        delete addSupplierRoleVo.appNavMenuList;
        this.$api.systemManage.roleAdd(addSupplierRoleVo).then((res) => {
          if (res.success) {
            this.$toastBack(res);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        roleName: "",
        navMenuList: [],
        menuId: [],
        appNavMenuList: [],
        appMenuId: [],
      };
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
    formData(id) {
      this.$api.systemManage.roleFindById({ id: id }).then((res) => {
        if (res.success) {
          this.form = res.data;
          this.form.navMenuList.forEach((item) => {
            if (item.isSelected == 1 && !item.children) {
              this.$set(item, "allSelected", true);
            }
            if (item.isSelected == 1 && item.children) {
              item.children.forEach((ele) => {
                if (ele.isSelected == 1 && !ele.children) {
                  this.$set(ele, "allSelected", true);
                  this.toggleAll(ele.allSelected, ele, item);
                } else if (ele.isSelected == 1 && ele.children) {
                  this.$set(ele, "selected", []);
                  ele.children.forEach((a) => {
                    if (a.isSelected == 1) {
                      ele.selected.push(a.id);
                      this.changeSelected(ele.selected, ele, item);
                    }
                  });
                }
              });
            }
          });
          this.form.appNavMenuList.forEach((item) => {
            if (item.isSelected == 1 && !item.children) {
              this.$set(item, "allSelected", true);
            }
            if (item.isSelected == 1 && item.children) {
              item.children.forEach((ele) => {
                if (ele.isSelected == 1 && !ele.children) {
                  this.$set(ele, "allSelected", true);
                  this.toggleAll(!ele.allSelected, ele, item);
                } else if (ele.isSelected == 1 && ele.children) {
                  this.$set(ele, "selected", []);
                  ele.children.forEach((a) => {
                    if (a.isSelected == 1) {
                      ele.selected.push(a.id);
                      this.changeSelected(ele.selected, ele, item);
                    }
                  });
                }
              });
            }
          });
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    chooseAll(checked, item) {
      if (checked && item.children) {
        item.children.forEach((i) => {
          this.$set(i, "allSelected", true);
          this.toggleAll(i.allSelected, i, item);
        });
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", true);
      } else if (checked && !item.children) {
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", true);
      } else {
        if (item.children) {
          item.children.forEach((i) => {
            this.$set(i, "allSelected", false);
            this.toggleAll(i.allSelected, i, item);
          });
        }
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", false);
      }
    },
    toggleAll(checked, item, par) {
      if (checked && item.children) {
        item.selected = item.children.map((item) => {
          return item.id;
        });
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", true);
      } else if (checked && !item.children) {
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", true);
      } else {
        item.selected = [];
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", false);
      }
      this.isAll(par);
    },
    isAll(par) {
      let a = 0;
      let b = 0;
      if (par) {
        par.children.forEach((i) => {
          if (i.allSelected) {
            a++;
          }
          if (i.indeterminate) {
            b++;
          }
        });
        if (a == par.children.length && b === 0) {
          this.$set(par, "indeterminate", false);
          this.$set(par, "allSelected", true);
        } else if (a === 0 && b === 0) {
          this.$set(par, "indeterminate", false);
          this.$set(par, "allSelected", false);
        } else {
          this.$set(par, "indeterminate", true);
          this.$set(par, "allSelected", false);
        }
      }
    },
    changeSelected(selected, item, par) {
      if (!selected || selected.length === 0) {
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", false);
      } else if (
        selected &&
        item.children &&
        item.children.length == selected.length
      ) {
        this.$set(item, "indeterminate", false);
        this.$set(item, "allSelected", true);
      } else {
        this.$set(item, "indeterminate", true);
        this.$set(item, "allSelected", false);
      }
      this.isAll(par);
    },
    disabledButton(selected, children, i, par) {
      console.log(selected, children, i, par);
      if (selected && children) {
        if (selected.indexOf(children[0].id) == -1) {
          //如果第一个不选中即没有查看权限后面的则不能选
          selected = [];
          this.$set(i, "selected", []);
        } else {
          this.$set(i, "disabled", false);
        }

        //if(i.menuAlias=='ModelView'){
        // if (selected && i.title == 'Model Select') {
        // 	if (selected.length == i.children.length) {
        // 		selected = selected.splice(2, selected.length - 2)
        // 	} else if (selected.length > 2) {
        // 		selected = selected.pop()
        // 	}
        // }

        if (!selected || selected.length === 0) {
          this.$set(i, "indeterminate", false);
          this.$set(i, "allSelected", false);
          this.$set(i, "disabled", false);
        } else if (selected && i.children.length == selected.length) {
          this.$set(i, "indeterminate", false);
          this.$set(i, "allSelected", true);
          this.$set(i, "disabled", false);
        } else {
          this.$set(i, "indeterminate", true);
          this.$set(i, "allSelected", false);
          this.$set(i, "disabled", false);
        }
        this.isAll(par);
      }
    },
  },
};
</script>
